<template>
    <div class="success-page">
        <cookies-popup />
        <div class="success-page-inner">
            <div class="header-logo-conainer">
                <router-link to="/" class="lp-youtube-promotion--logo-container">
                    <img
                    class="lp-youtube-promotion--logo"
                    src="/images/logo/D&D_h_logo_full_black.png"
                    alt="D&amp;D logo"
                    />
                </router-link>
            </div>
            <h2>{{ $t('checkoutSuccess.title') }}</h2>
            <h3>{{ $t('checkoutSuccess.title2') }}</h3>
            <p>{{ $t('checkoutSuccess.text') }}</p>
            <div class="footer-panel"></div>
            <p> {{ $t('checkoutSuccess.note') }} <router-link to="youtube">{{ $t('checkoutSuccess.linkText') }} </router-link></p>
        
            <div class="footer-wrapper">
                <footer-page />
            </div>
        </div>
    </div>  
</template>
<script>
import cookiesPopup from './../components/cookies-popup';
import footerPage from './../sections/footer-small';
export default {
    mounted() {
           this.$http.post('order/confirm', { 'external_id': this.$route.query.order })
             .then(this.onSuccess, this.onError);    
    },
    methods: {
        onSuccess() {
            setTimeout(() => this.$router.push({name: 'YouTube Promo LP Long'}), 15000);
            window.gtag('event', 'conversion', {'send_to': 'AW-594863910/ezPtCI_55PQBEKbO05sC'});
        },
        onError() {
            this.$router.push({name: 'checkout-payment-error'});
        }
    },
    components: {
        cookiesPopup, footerPage
    }
}
</script>
<style lang="scss" scoped>
@import "../scss/app";
@import '../scss/youtube-lp';
.success-page {
    background: #F5F6FC;
    position: relative;
    min-height: 100vh;
    padding: 30vh 0 0;
}

.footer-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10vh;
}

.header-logo-conainer {
    position: absolute;
    top:0;
    left:0;
    right:0;
    padding: 24px 34px;
    height: 88px;

    img {
        height: 40px;
        width: 94px;
    }
}

.dnd-btn-w:hover {
    &:before{
      background:rgba($color: #F5F6FC, $alpha: 1.0);
    }
}
.success-page-inner {
    width: 80%;
    margin:auto;

    h2 {
        position: relative;
        text-align: center;
        font-family: $family-bold;
        padding-bottom: 55px;
        @include font-size(40px);

        &::after {
            content: '';
            position: absolute;
            top: 70px;
            width: 200px;
            margin:auto;
            height: 2px;
            background-color: #ccc;
            left: 50%;
            margin-left: -100px;
        }
    }

    h3 {
        font-family: $family-bold;
        font-family: $family-light;
        @include font-size(19px);
        text-align: center;
    }

    p {
        width: 60%;
        padding: 3px 0 0;
        text-align: center;
        margin:auto;
        font-family: $family-light;
        @include font-size(19px);
    }
}

.footer-panel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 20px;
}

@media (max-width: 576px) {
    .success-page {
        padding: 20vh 0 0;
    }
    .success-page-inner {
        h2 {
            &::after {
                content: '';
                position: absolute;
                top: 115px;
                width: 200px;
                margin:auto;
                height: 2px;
                background-color: #ccc;
                left: 50%;
                margin-left: -100px;
            }
        }
    }
}
</style>